<!-- ########################## <Template> ########################## -->
<template lang="">

        <Header></Header>
        <div class="pageTitle" > 
            <font-awesome-icon icon="caret-right" /> 
            Gestion des clients
        </div>
        
        <div class="pageBodyContainer">
        
            <!--  -->
            
            <!--  -->
            <div>
                <div class="card">
                    <DataTable ref="dt" :value="gridData"  dataKey="id" 
                        :filters="filters" class="p-datatable-sm"
                        responsiveLayout="scroll">
                        <template #header>
                            <div class="table-header p-d-flex p-flex-column p-flex-md-row">
                                <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                                    <P-Button label="Nouveau client" icon="pi pi-plus" class="p-button-outlined p-button-success p-mr-2" @click="openAddDialog" />
                                </h5>
                                &nbsp;
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters['global'].value" placeholder="Recherche..." />
                                </span>
                            </div>
                        </template>

                        <!-- <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column> -->
                        <Column :exportable="false">
                            <template #body="slotProps">
                                <P-Button icon="pi pi-pencil" class="p-button-outlined p-button-rounded p-button-success p-mr-2" @click="openEditDialog(slotProps.data)" />
                                <P-Button icon="pi pi-trash" class="p-button-outlined p-button-rounded p-button-danger" @click="confirmDeleteSelected(slotProps.data)" />
                            </template>
                        </Column>
                        <Column field="raisonSociale" header="Raison sociale" :sortable="true" style="min-width:16rem"></Column>
                        <Column field="ice" header="ICE" :sortable="true" style="min-width:16rem"></Column>
                        <Column field="rc" header="RC" :sortable="true" style="min-width:16rem"></Column>
                        <Column field="ville" header="Ville" :sortable="true" style="min-width:16rem"></Column>
                        <Column field="actif" header="Actif" :sortable="true" style="min-width:16rem">
                            <template #body="slotProps">
                                    <InputSwitch v-model="slotProps.data.actif" @change="onActifChange(slotProps.data)" />
                            </template>
                        </Column>

                    <template #footer>
                        Total {{gridData.length}}
                    </template>
                        
                    </DataTable>
                </div>

            </div>

            <!-- Delete Dialog -->
            

            <!-- Edit Dialog -->
            <Dialog v-model:visible="showEditDialogFlag" 
                    :style="{width: '450px'}" 
                    :header="(selectedGridItem.id)?'Edition':'Création d\'un nouveau client'" 
                    :modal="true" class="p-fluid"
                    @hide="closeEditDialog"
            >
                <V-Form ref="myForm" @submit="onSubmit" :validation-schema="myValidationSchema" autocomplete="off">
                    <div class="p-field">
                        <label for="raisonSociale">Raison sociale</label>
                        <V-Field as="InputText" name="raisonSociale" v-model.trim="selectedGridItem.raisonSociale"    />
                        <ErrorMessage name="raisonSociale" class="error-feedback" />
                        
                    </div>

                    <div class="p-field">
                        <label for="ice">ICE</label>
                        <V-Field name="ice"  v-model="selectedGridItem.ice" v-slot="{ field, errors, errorMessage }" >
                            <InputMask name="ice" :modelValue="selectedGridItem.ice" v-model="selectedGridItem.ice"   mask="999999999-9999-99" :autoClear="false" :unmask="true"  />
                        </V-Field> 
                        
                        <ErrorMessage name="ice" class="error-feedback" />
                    </div>

                    <div class="p-field">
                        <label for="rc" class="p-mb-3">RC</label>
                        <V-Field as="InputText" name="rc" v-model.trim="selectedGridItem.rc"   />
                        <ErrorMessage name="rc" class="error-feedback" />
                    </div>

                    <div class="p-field">
                        <label for="ville" class="p-mb-3">Ville</label>
                        <V-Field as="InputText" name="ville" v-model.trim="selectedGridItem.ville"   />
                        <ErrorMessage name="ville" class="error-feedback" />
                    </div>
                    
                    <div class="p-field">
                        <label for="Roles" class="p-mb-3">Roles disponibles</label>
                        <MultiSelect v-model="chosenRoles" 
                        :options="availableRoles" 
                        optionLabel="titre" 
                        placeholder="Selectionner les Roles par défaut"
                        class="w-full md:w-20rem" />
                        <ErrorMessage name="Roles" class="error-feedback" />
                    </div>
                    

                    <div class="p-field">
                        <label for="activerChoixLimiteCreditMinimum" class="p-mb-3">Activer choix limite de credit minimum</label>
                        <br/><InputSwitch v-model="selectedGridItem.activerChoixLimiteCreditMinimum" />
                        <ErrorMessage name="activerChoixLimiteCreditMinimum" class="error-feedback" />
                    </div>

                    <div class="p-field" v-show="selectedGridItem.activerChoixLimiteCreditMinimum">
                        <label for="LimiteCreditMinimum" class="p-mb-3">Limite de crédit minimum</label>
                        <br/><InputNumber id="locale-us" v-model="selectedGridItem.limiteCreditMinimum" mode="decimal" locale="ar-MA" :minFractionDigits="0"  />
                        <ErrorMessage name="LimiteCreditMinimum" class="error-feedback" />
                    </div>

                    <div class="p-field">
                        <label for="activerWorkflowValidation" class="p-mb-3">Workflow (soumission et validation score)</label>
                        <br/><InputSwitch v-model="selectedGridItem.activerWorkflowValidation" />
                        <ErrorMessage name="activerWorkflowValidation" class="error-feedback" />
                    </div>
                    
                    <div class="p-field" v-if="selectedGridItem.id?true:false">
                        <label for="actif" class="p-mb-3">Actif</label>
                        <br/>
                        <InputSwitch id="actif" v-model="selectedGridItem.actif" />
                    </div>
                    <!-- {{selectedGridItem.activerOmpic}} -->
                    <div class="p-field" v-if="selectedGridItem.id?true:false">
                        <label for="activerOmpic" class="p-mb-3">Activer Import Ompic</label>
                        <br/>
                        <InputSwitch id="activerOmpic" v-model="selectedGridItem.activerOmpic" />
                    </div>

                    <div class="p-field" v-if="selectedGridItem.id?true:false">
                        <label for="activerInforisk" class="p-mb-3">Activer Import Inforisk</label>
                        <br/>
                        <InputSwitch id="activerInforisk" v-model="selectedGridItem.activerInforisk" />
                    </div>

                    <!-- <div class="p-field">
                        <label for="loginInforisk" class="p-mb-3">Nom d'utilisateur inforisk</label>
                        <V-Field as="InputText" name="loginInforisk" v-model.trim="selectedGridItem.loginInforisk"   />
                        <ErrorMessage name="loginInforisk" class="error-feedback" />
                    </div>

                    <div class="p-field">
                        <label for="passwordInforisk" class="p-mb-3">Mot de passe inforisk</label>
                        <Password name="passwordInforisk" v-model.trim="userPasswordInforisk"   />
                        <ErrorMessage name="passwordInforisk" class="error-feedback" />
                    </div> -->

                <div class="p-d-flex p-jc-end">
                    
                        <button class="btn btn-danger btn-block"  @click="closeEditDialog">
                            <span>Annuler</span>
                        </button> &nbsp;
                        <button  class="btn btn-primary btn-block" type="submit">
                            <span>Enregistrer</span>
                        </button>
                    </div>

                </V-Form>
                
            </Dialog>
            
            <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>

        </div>
    
</template>

<!-- ########################## <Script> ########################## -->
<script>
//--------------------------- Imports --------------------------
import Header from "./Header.vue";
import CRUDService from "../1-common-components/crud.service";
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
import kendo from '@progress/kendo-ui'
import {_} from 'vue-underscore';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import Dialog from 'primevue/dialog'
import ConfirmDialog from 'primevue/confirmdialog';
import { Field, Form, ErrorMessage, } from 'vee-validate';
import * as yup from 'yup';
import Password  from 'primevue/password';
import AuthService from '../0-auth-components/auth.service';
import MultiSelect from 'primevue/multiselect';

export default {
    name: "AdminClients",
    //--------------------------- Components --------------------------
    components: {
        Header, 
        Dialog,
        ConfirmDialog,
        'kendo-grid': Grid,
        'kendo-grid-column': GridColumn,
        'kendo-datasource': KendoDataSource,
        'V-Field':Field,
        'V-Form':Form,
        ErrorMessage,
        Password,
        MultiSelect
    },
    //--------------------------- setup --------------------------
    // setup() {
 
    //     return {
    //         onSubmit,
    //         myValidationSchema,
    //     };
    // },
    //--------------------------- Data --------------------------
    computed: {
        isEditForm()
        {
            return this.selectedGridItem.id > 0 ? true:false;
        }
    },
    data() {
        // Using yup to generate a validation schema
        // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
        const myValidationSchema = yup.object().shape({
                raisonSociale: yup.string().max(50,"Maximum 50").required("Raison sociale est obligatoire !"),
                ice: yup.string().min(15,"ICE doit comporter 15 numéros").max(15,"ICE doit comporter 15 numéros").required("ICE est obligatoire !"),
                rc: yup.string().max(15,"Maximum 15").required("RC est obligatoire !"),
                ville: yup.string().max(50,"Maximum 50").required("Ville est obligatoire !"),
        });
        return {
            gridData: [],
            showEditDialogFlag: false,
            selectedGridItem: {id:0, actid:true},
            selectedGridInitialState: {id:0},
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            errorMessage: "",
            myValidationSchema,
            userPasswordInforisk:"",
            chosenRoles:[], 
            availableRoles:[]
        } // end result
    }, //end data()
    //--------------------------- Mounted --------------------------
    mounted() {
        this.log("----------- mounted()");

        this.loadGridData();

    }, //end mounted
    //--------------------------- Created --------------------------
    created() {
        this.log("----------- created()");
    },
    //--------------------------- Methods --------------------------
    methods: {
        onSubmit(values, actions) {
            this.log("onSubmit");
            this.log(values);

            if(this.isEditForm) {
                if(this.userPasswordInforisk.length > 0)
                {
                    var r = confirm("Etes-vous sûr de vouloir modifier le mot de passe INFORISK de l'utilisateur ?");
                    if (r == false) {
                        return;
                    }
                }
            }

            this.saveGridItem();
        },
        saveGridItem()
        {
            this.log("saveGridItem");
            this.log(this.selectedGridItem);
            
            this.selectedGridItem.activerWorkflowValidation=this.selectedGridItem.activerWorkflowValidation?1:0
            this.selectedGridItem.activerChoixLimiteCreditMinimum=this.selectedGridItem.activerChoixLimiteCreditMinimum?1:0
            this.selectedGridItem.activerOmpic=this.selectedGridItem.activerOmpic?1:0
            this.selectedGridItem.activerInforisk=this.selectedGridItem.activerInforisk?1:0
            
            

            if(this.selectedGridItem.id > 0)
            {
                CRUDService.update(this.selectedGridItem,'client').then(
                    (response) => {
                        this.log(response.data);
                        this.toastSuccess("Client "+ this.selectedGridItem.raisonSociale +" modifié avec succès");
                        this.selectedGridItemInitialState = response.data;
                        this.showEditDialogFlag = false;
                        this.InforiskLoading = true

                        var idsToLink = this.chosenRoles.map(function(role){
                            return role.id
                        }).join(",")

                        if(!idsToLink)
                        {
                            idsToLink = null
                        }

                        var parameters = {ClientId:this.selectedGridItem.id,RoleIds:idsToLink}


                        CRUDService.listData('AddfrontRolesRightsByClient',parameters).then(function(response){
                            if(response.data)
                            {
                                //this.companyInforisk =  response.data;
                            }
                            else
                            {
                                alert("Erreur d'assignation de roles")
                                this.InforiskLoading = false
                            }
                        })
                        
                        
                        if(this.userPasswordInforisk.length > 0)
                        {
                            this.changePasswordSave(this.userPasswordInforisk,response.data.id);
                        }
                    },
                    (error) => {
                        this.toastError("Echec sauvegarde de l''enregistrement !");
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                );
            }
            else
            {
                CRUDService.create(this.selectedGridItem,'client').then(
                    (response) => {
                        this.log(response.data);
                        this.gridData.push(response.data);
                        this.toastSuccess("Client "+ this.selectedGridItem.raisonSociale +" créé avec succès");
                        this.selectedGridItemInitialState = response.data;
                        this.showEditDialogFlag = false;

                        var idsToLink = this.chosenRoles.map(function(role){
                            return role.id
                        }).join(",")

                        if(!idsToLink)
                        {
                            idsToLink = null
                        }

                        var parameters = {ClientId:this.selectedGridItem.id,RoleIds:idsToLink}


                        CRUDService.listData('AddfrontRolesRightsByClient',parameters).then(function(response){
                            if(response.data)
                            {
                                //this.companyInforisk =  response.data;
                            }
                            else
                            {
                                alert("Erreur d'assignation de roles")
                                this.InforiskLoading = false
                            }
                        })

                        this.changePasswordSave(this.userPasswordInforisk,response.data.id);
                    },
                    (error) => {
                        this.toastError("Echec création de l''enregistrement !");
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                );
            }
        },
        changePasswordSave: function(password, clientId)
        {
            this.log("changePasswordInforisk");

            AuthService.asyncResetClientInforiskPassword(password,clientId).then(
                (response) => {
                    this.log("Reset success: " + response.data.token);
                    this.log("response.data: " + response.data);
                    this.errorMessage = "Mot de passe a été réinitialisé avec succès";
                    this.toastSuccess("Mot de passe a été mis à jour avec succès");
                },
                (error) => {
                    this.log("Reset error: " + error);
                    this.errorMessage = "ERREUR: Mot de pass de '"+user.username+"' n'a pas été réinitialisé !! "+error;
                    this.toastError("Mise à jour du mot de passe de '"+user.username+"' a échoué");
                }
            );


        },
        openAddDialog() {
            this.openEditDialog({id:0, actif:true, poidsScoreFinancier:50,poidsScoreQualitatif:50});
        },
        openEditDialog(selectedItem){
            this.log("openEditDialog");
            this.log(selectedItem);
            this.showEditDialogFlag = true;
            this.selectedGridItem = selectedItem;
            
            this.selectedGridItem.activerWorkflowValidation=this.selectedGridItem.activerWorkflowValidation?true:false
            this.selectedGridItem.activerChoixLimiteCreditMinimum=this.selectedGridItem.activerChoixLimiteCreditMinimum?true:false
            this.selectedGridItem.limiteCreditMinimum = this.selectedGridItem.limiteCreditMinimum?this.selectedGridItem.limiteCreditMinimum:0
            this.selectedGridItem.activerOmpic=this.selectedGridItem.activerOmpic?true:false
            this.selectedGridItem.activerInforisk=this.selectedGridItem.activerInforisk?true:false
            // debugger;
            
            
            
            if(this.selectedGridItem.id>0)
            {
              
                CRUDService.list('frontRolesRightsByClient/'+selectedItem.id).then( //ActiverWorkflowValidation
                    (response) => {
                        this.log(response.data);
                        this.availableRoles = response.data;

                        this.chosenRoles = response.data.filter(function(c){
                            return c.isRole
                        })
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                );
              
            }
            else
            {
                this.chosenRoles = []
            }

            this.selectedGridItemInitialState = { ...this.selectedGridItem }; //this is an object copy using decomposition
        },
        closeEditDialog(){
            this.log("closeEditDialog");
            
            for (let index = 0; index < this.gridData.length; index++) {
                var element = this.gridData[index];
                if(element.id == this.selectedGridItem.id)
                {
                    this.log(this.selectedGridItemInitialState);
                    this.gridData[index] = this.selectedGridItemInitialState;
                    break;
                }
            }

            this.showEditDialogFlag = false;
        },
        confirmDeleteSelected(selectedItem){
            this.log("confirmDeleteSelected: " + selectedItem);
            this.log(selectedItem);
            this.selectedGridItem = selectedItem

            this.$confirm.require({
                message: 'Etes-vous sûr de vouloir supprimer le client '+this.selectedGridItem.raisonSociale+'?',
                header: 'Confirmation',
                icon: 'pi pi-info-circle',
                acceptLabel:'Oui',
                rejectLabel:'Non',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.deleteGridItem();
                },
                reject: () => {
                    this.$confirm.close();
                }
            });
        },
        deleteGridItem()
        {
            this.log("deleteGridItem");
            CRUDService.delete(this.selectedGridItem,'client').then(
                (response) => {
                    this.log(response.data);
                    this.gridData = this.gridData.filter(d => d.id != this.selectedGridItem.id);
                    this.toastSuccess("Client "+ this.selectedGridItem.raisonSociale +" supprimé avec succès");
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        loadGridData: function()
        {
            this.log("------loadGridData");
            CRUDService.list('clientscustom').then( //ActiverWorkflowValidation
                (response) => {
                    this.log(response.data);
                    this.gridData = response.data;
                    
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
            
        },
        onActifChange(selectedItem)
        {
            this.selectedGridItem = selectedItem;
            this.saveGridItem();
        },

    }
  
    //---------------------------
} //end export default
</script>

<!-- ########################## <Style> ########################## -->
<style >

.table-header {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */

    @media screen and (max-width: 960px) {
        align-items: start;
	}
}


</style>